
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="mt-4 mb-5">{{$store.getters.language.data.iligal_days.add_btn}}</h1>

			<form @submit.prevent="addIligalDays" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-autocomplete class="mx-1" clearable :items="employees" v-model="iligal_days.employee_id" dense filled outlined item-text="employee_name" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_name">
						</v-autocomplete>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="iligal_days.iligal_day_date" type="date" :label="$store.getters.language.data.iligal_days.iligal_day_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<!-- 					<v-flex xs12 lg4 xl4 md6 sm6>

                        <v-text-field v-model="iligal_days.iligal_day_date_insert" type="datetime-local" :label="$store.getters.language.data.iligal_days.iligal_day_date_insert" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="iligal_days.iligal_day_amount" type="number" :label="$store.getters.language.data.iligal_days.iligal_day_amount" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="users" v-model="iligal_days.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg12 xl12 md12 sm12>
						<v-textarea v-model="iligal_days.iligal_day_note" type="textarea" :label="$store.getters.language.data.iligal_days.iligal_day_note" dense class="mx-1" filled outlined>
						</v-textarea>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit" width="100%">{{$store.getters.language.data.iligal_days.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-divider class="my-8"></v-divider>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field>

					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="iligal_day_id">
								<template v-slot:[`item.iligal_day_id`]="{ item }">
									<div>
										<v-btn icon :to="'/iligal_days-list/'+item.iligal_day_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectIligalDays(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
								<template v-slot:[`item.iligal_day_date`]="{ item }">
									<div>
										{{new Date(item.iligal_day_date).toLocaleDateString()}}

									</div>
								</template>
								<template v-slot:[`item.iligal_day_date_insert`]="{ item }">
									<div>
										{{new Date(item.iligal_day_date_insert).toLocaleString()}}
									</div>
								</template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteIligalDaysList">{{$store.getters.language.data.iligal_days.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.iligal_days.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.iligal_days.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteIligalDays(selected_iligal_days.iligal_day_id)">
						{{$store.getters.language.data.iligal_days.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import employeesRequest from '../../requests/employees.request.js'
	import requests from './../../requests/iligal_days.request.js'
	export default {
		data() {
			return {
				iligal_days: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_iligal_days: {},
				delete_dialog: false,
				headers: [

					{
						text: this.$store.getters.language.data.employees.employee_name,
						align: 'start',
						sortable: true,
						value: 'employee_name',
					},
					{
						text: this.$store.getters.language.data.iligal_days.iligal_day_date,
						align: 'start',
						sortable: true,
						value: 'iligal_day_date',
					},
                    {
                        text: this.$store.getters.language.data.iligal_days.iligal_day_amount,
                        align: 'start',
                        sortable: true,
                        value: 'iligal_day_amount',
                    }
                    ,
					{
						text: this.$store.getters.language.data.iligal_days.iligal_day_note,
						align: 'start',
						sortable: true,
						value: 'iligal_day_note',
					},
					{
						text: this.$store.getters.language.data.iligal_days.iligal_day_date_insert,
						align: 'start',
						sortable: true,
						value: 'iligal_day_date_insert',
					},
					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'iligal_day_id',
					}
				],
			}
		},
		computed: {
			// employees() {
			// 	return this.$store.getters.employees_list
			// },
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},
		},
		mounted() {
			this.iligal_days.user_id = this.user.user_id
			this.readIligalDays();
			this.readEmployees()

		},
		methods: {
			addIligalDays() {
				this.loading_btn = true
				requests.createIligalDays(this.iligal_days).then(r => {
					if (r.status == 200) {
						this.iligal_days = {
							user_id: this.user.user_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'IligalDays Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add IligalDays',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteIligalDays(iligal_day_id) {
				requests.deleteIligalDays(iligal_day_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.iligal_day_id != iligal_day_id
						})
						this.snackbar = {
							value: true,
							text: 'IligalDays Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteIligalDaysList() {
				let ids = this.selected_rows.map(m => m.iligal_day_id)
				requests.deleteIligalDaysList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.iligal_day_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' IligalDays Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},
			readEmployees() {
				this.loading = true
				employeesRequest.getAllEmployees().then(r => {
					if (r.status == 200) {
						this.employees = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readIligalDays() {
				this.loading = true
				requests.getAllIligalDays().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read IligalDays',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read IligalDays',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectIligalDays(i) {
				this.selected_iligal_days = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    