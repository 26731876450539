
import axios from 'axios';
export default {
    
    async createEmployees(params)  {
        return await axios.post(`employees/create` , params)
    },
    async createEmployeesList(params)  {
        return await axios.post(`employees/create/list` , params)
    },
    async updateEmployeesColumn(column , value , data)  {
        return await axios.put(`employees/update_list?${column}=${value}` , data)
    },
    async deleteEmployeesList(list)  {
        return await axios.delete(`employees/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async getAllEmployeeDetail(employee_id)  {
        return await axios.get(`employees/all_details/${employee_id}`)
    }
    ,
    async getEmployeeDetails(employee_id)  {
        return await axios.post(`employees/details/${employee_id}`)
    }
    ,
    async reportEmployees(column , value)  {
        return await axios.get(`employees/report?${column}=${value}`)
    },
    async getAllEmployees()  {
        return await axios.post(`employees/all`)
    },
    async getAllDeactiveEmployees()  {
        return await axios.post(`employees/allde`)
    },
    async getOneEmployees(employee_id)  {
        return await axios.post(`employees/all/${employee_id}`)
    },
    async getEmployeesByColumn(column , value)  {
        return await axios.get(`employees/filter?column=${column}&value=${value}`)
    },
    async deleteEmployees(employee_id)  {
        return await axios.delete(`employees/delete/${employee_id}`)
    },
    async updateEmployees(employee_id , params)  {
        return await axios.put(`employees/update/${employee_id}` , params)
    }
}