
import axios from 'axios';
export default {
    
    async createIligalDays(params)  {
        return await axios.post(`iligal_days/create` , params)
    },
    async createIligalDaysList(params)  {
        return await axios.post(`iligal_days/create/list` , params)
    },
    async updateIligalDaysColumn(column , value , data)  {
        return await axios.put(`iligal_days/update_list?${column}=${value}` , data)
    },
    async deleteIligalDaysList(list)  {
        return await axios.delete(`iligal_days/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportIligalDays(column , value)  {
        return await axios.get(`iligal_days/report?${column}=${value}`)
    },
    async getAllIligalDays()  {
        return await axios.get(`iligal_days/all`)
    },
    async getOneIligalDays(iligal_day_id)  {
        return await axios.get(`iligal_days/all/${iligal_day_id}`)
    },
    async getIligalDaysByColumn(column , value)  {
        return await axios.get(`iligal_days/filter?column=${column}&value=${value}`)
    },
    async deleteIligalDays(iligal_day_id)  {
        return await axios.delete(`iligal_days/delete/${iligal_day_id}`)
    },
    async updateIligalDays(iligal_day_id , params)  {
        return await axios.put(`iligal_days/update/${iligal_day_id}` , params)
    }
}